// customize some Bootstrap variables
//$primary: darken(#428bca, 20%);
$primary: #4A505C;
$secondary: #D5E04C;
$info: #42A5F5;
$success: #66BB6A;
$danger: #EF5350;
$dark: #111111;
$light: #EDF1F2;

$theme-colors: (
        "primaryDark": #373C43,
        "primaryLight": #E4E5E7,
        "secondaryDark": #7B8334,
        "secondaryLight": #F2F6C9,
);

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

/* Border width */
.border-1 {
  border-width:1px !important;
}
.border-2 {
  border-width:3px !important;
}
.border-3 {
  border-width:3px !important;
}
.border-4 {
  border-width:4px !important;
}
.border-5 {
  border-width:5px !important;
}
