/* Footer */

.container {
    margin-bottom: 70px;
}

/* Login */

#login input {
    border: none;
    box-shadow: none;
}

input:focus {
    outline: none;
}

#login input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #111111 inset;
    -webkit-text-fill-color: #D8D8D8;
}

/* Button */

button:focus, input:focus {
    box-shadow: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

/* Pagination */
.pagination{
    margin-bottom: 0;
}

/* Table */
.table{
    margin-bottom: 0;
}

.list-group-striped .list-group-item:nth-of-type(even){
    background-color: rgba(0, 0, 0, 0.05);
}